<template>
  <div class="Userhl_zuopin">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="已发布作品" name="first">
        <ul class="Userhl_zuopinul">
          <li
            class="Userhl_zuopinulli"
            v-for="(item, index) in list"
            :key="index"
            v-show="item.title"
          >
            <div class="Userhl_zuopinulliimg">
              <img :src="item.titlepic" alt="" />
              <div class="Userhl_zuopinulliimgzt">
                {{
                  item.fake_delete == 1
                    ? "已发布"
                    : item.fake_delete == 2
                    ? "待审核"
                    : item.fake_delete == 3
                    ? "审核失败"
                    : "已下线"
                }}
              </div>
              <div class="Userhl_zuopinulliimgbox">
                <div class="Userhl_zuopinulliimgboxsm" @click="bj(item.id)">
                  <icon-svg icon-class="iconedit" />
                  <p>编辑</p>
                </div>
                <div class="Userhl_zuopinulliimgboxsm" @click="del(item.id)">
                  <icon-svg icon-class="icondel" />
                  <p>删除</p>
                </div>
                <div
                  class="Userhl_zuopinulliimgboxsm"
                  @click="xiaoshou(item.id)"
                  v-if="item.money > 0 ? true : false"
                >
                  <icon-svg icon-class="iconsalelist" />
                  <p>销售记录</p>
                </div>
              </div>
            </div>
            <p class="Userhl_zuopinulliname" @click="zuopin(item.id)">
              {{ item.title }}
            </p>
            <p
              class="Userhl_zuopinullitype"
              v-if="item.activity_one == '' ? false : true"
            >
              <span>{{ item.activity_one }}</span>
              -<span>{{ item.activity_two }}</span>
            </p>
            <p
              class="Userhl_zuopinullitype"
              v-if="item.activity_one == '' ? true : false"
            >
              {{ item.major }}
            </p>
            <div class="Userhl_zuopinullibtm">
              <div class="Userhl_zuopinullibtml">
                <icon-svg icon-class="iconlook" />
                <span>{{ item.browse == null ? 0 : item.browse }}</span>
                <icon-svg icon-class="iconmessages" />
                <span>{{ item.plnum == null ? 0 : item.plnum }}</span>
                <icon-svg icon-class="icongood" />
                <span>{{ item.give_like == null ? 0 : item.give_like }}</span>
              </div>
              <div class="Userhl_zuopinullibtmr">{{ item.create_time }}</div>
            </div>
            <div
              class="Userhl_zuopinullimm"
              v-if="item.money > 0 ? false : true"
            >
              <img
                src="https://task.hozoin.cn/Works/20210112/sj-5398800896.png"
                alt=""
              />
              <span>免费</span>
            </div>
            <div
              class="Userhl_zuopinullimm"
              v-if="item.money > 0 ? true : false"
            >
              <img
                src="https://task.hozoin.cn/Works/20210112/sj-9232138810.png"
                alt=""
              />
              <span>￥{{ item.money }}</span>
            </div>
          </li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
          <li class="Userhl_zuopinulli_zero" v-if="zerotype"></li>
        </ul>
        <el-button class="linkbtn" plain @click="linkxm('/Sjfabu')"
          >立即发布作品</el-button
        >
      </el-tab-pane>
      <el-tab-pane label="已购买作品" name="two">
        <ul class="Userhl_zuopinul">
          <li
            class="Userhl_zuopinulli"
            v-for="(item, index) in listbuy"
            :key="index"
            v-show="item.title"
          >
            <div class="Userhl_zuopinulliimg">
              <img :src="item.titlepic" alt="" />
              <div class="Userhl_zuopinulliimgbox">
                <div class="Userhl_zuopinulliimgboxsm">
                  <a :href="item.file_url" ref="file" :download="downloadName">
                    <icon-svg icon-class="iconxiazai" />
                    <p>{{ item.file_url == "" ? "暂无" : "下载" }}</p>
                  </a>
                </div>
              </div>
            </div>
            <p class="Userhl_zuopinulliname" @click="zuopin(item.order_id)">
              {{ item.title }}
            </p>
            <p class="Userhl_zuopinullitype">
              <span>{{ item.activity_one }}</span>
              -<span>{{ item.activity_two }}</span>
            </p>
            <div class="Userhl_zuopinullibtm">
              <div class="Userhl_zuopinullibtml">
                <icon-svg icon-class="iconlook" />
                <span>{{ item.browse == null ? 0 : item.browse }}</span>
                <icon-svg icon-class="iconmessages" />
                <span>{{ item.plnum == null ? 0 : item.plnum }}</span>
                <icon-svg icon-class="icongood" />
                <span>{{ item.give_like == null ? 0 : item.give_like }}</span>
              </div>
              <div class="Userhl_zuopinullibtmr">2020-10-10</div>
            </div>
            <div class="Userhl_zuopinullimm">
              <img
                src="https://task.hozoin.cn/Works/20210112/sj-9232138810.png"
                alt=""
              />
              <span>￥{{ item.money }}</span>
            </div>
          </li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
          <li class="Userhl_zuopinulli_zero" v-if="buy_zerotype"></li>
        </ul>
        <el-button class="linkbtn" plain @click="linkxm('/Chengguo')"
          >立即购买作品</el-button
        >
      </el-tab-pane>
    </el-tabs>
    <el-pagination
      :pager-count="5"
      @current-change="currentchange"
      :page-size="limit"
      background
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="20%">
      <span>确定删除吗</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delzuopin">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="销售记录" :visible.sync="xiaoshoutype" width="30%">
      <el-table :data="xsall" border style="width: 100%" stripe>
        <el-table-column prop="username" label="已购买设计师" style="">
        </el-table-column>
        <el-table-column prop="title" label="作品名称" style="">
        </el-table-column>
        <el-table-column prop="create_time" label="订单时间"></el-table-column>
      </el-table>
      <el-pagination
        :pager-count="5"
        :page-size="limit"
        background
        layout="prev, pager, next"
        :total="sxnum"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            xiaoshoutype = false;
            xsall = [];
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      xiaoshoutype: false,
      activeName: "first",
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      limit: 9,
      list: [],
      listbuy: [],
      downloadName: "附件",
      buy_zerotype: false,
      zerotype: false,
      lizero: false,
      userinfo: {},
      delid: 0,
      xsall: [],
      sxnum: 0,
      total: 0,
      indexid: 0,
    };
  },
  methods: {
    currentchange(index) {
      if (this.indexid == 0) {
        this.axiosqq(index);
      } else {
        this.axiosqq_buy(index);
      }
    },
    linkxm(link) {
      this.$router.push({ path: link });
    },
    handleClick(tab) {
      this.indexid = tab.index;
      if (this.indexid == 0) {
        this.axiosqq(1);
      } else {
        this.axiosqq_buy(1);
      }
    },
    axiosqq(page) {
      var that = this;
      that.lizero = true;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getUserWorksList",
          method: "post",
          data: {
            page,
            limit: that.limit,
            word_type: "1",
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.lizero = false;
            that.total = res.data.result.count;
            var listnum = res.data.result.count % 3;
            if (listnum == 2) {
              that.zerotype = true;
            }
            that.list = res.data.result.list;
          } else {
            that.lizero = true;
            // that.$message(res.data.messages);
          }
        });
    },
    axiosqq_buy(page) {
      var that = this;
      that.lizero = true;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getUserPayWorksList",
          method: "post",
          data: {
            page,
            limit: that.limit,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.lizero = false;
            that.total = res.data.result.count;
            var listnum = res.data.result.count % 2;
            if (listnum == 2) {
              that.buy_zerotype = true;
            }
            that.listbuy = res.data.result.list;
          } else {
            that.lizero = true;
          }
        });
    },
    down() {
      this.$refs.file.click();
    },
    zuopin(id) {
      this.$router.push({ path: "/Chengguo_c", query: { data: id } });
    },
    xiaoshou(id) {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/userWorksOrder",
          method: "post",
          data: {
            page: "1",
            limit: that.limit,
            id,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.sxnum = res.data.result.count;
            that.xsall = res.data.result.list;
            this.xiaoshoutype = true;
          } else {
            this.xiaoshoutype = true;
          }
        });
    },
    del(id) {
      this.delid = id;
      this.dialogVisible = true;
    },
    delzuopin() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/userWorksDelete",
          method: "post",
          data: {
            id: that.delid,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.$message("删除成功");
            that.dialogVisible = false;
            location.reload();
          } else {
            that.$message(res.data.messages);
          }
        });
    },
    bj(id) {
      this.$router.push({
         path: "/Sjfabu", 
         query: { 
           data: id, 
           usertypeid:this.userinfo.groupid,
           bjtypeid:"zuopin",
         } 
      });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.axiosqq(1);
  },
};
</script>
